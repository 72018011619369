import React, { useEffect, useState } from "react"
import HeaderDesktop from "./HeaderDesktop"
import HeaderMobile from "./HeaderMobile"
import { useMediaQuery } from "react-responsive"
import { HeaderProps } from "@/app/interface/components"

const Header: React.FC<HeaderProps> = ({ setIsAlertModalOpen }) => {
    const isDesktop = useMediaQuery({ minWidth: 1140 })
    const [isMounted, setIsMounted] = useState(false)

    useEffect(() => {
        setIsMounted(true)
    }, [])

    // Renderiza nulo até que o componente esteja montado para evitar problemas de hidratação
    if (!isMounted) {
        return null
    }

    return (
        <>
            {isDesktop ? (
                <HeaderDesktop setIsAlertModalOpen={setIsAlertModalOpen} />
            ) : (
                <HeaderMobile setIsAlertModalOpen={setIsAlertModalOpen} />
            )}
        </>
    )
}

export default React.memo(Header)
