import React, { useEffect, useRef, useState } from "react"
import LogoContainer from "../../LogoContainer"
import styles from "@/styles/mobile/Header.module.scss"
import { useTheme } from "@/app/contexts/ThemeContext"
import ExploreContainer from "./ExploreContainer"
import ProductContainer from "./ProductContainer"
import { HeaderProps } from "@/app/interface/components"
import CustomLink from "../../CustomLink"

const HeaderMobile: React.FC<HeaderProps> = ({ setIsAlertModalOpen }) => {
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)
    const [isExploreOpen, setIsExploreOpen] = useState<boolean>(false)
    const [isProductOpen, setIsProductOpen] = useState<boolean>(false)
    const { theme, toggleTheme } = useTheme()
    const menuRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        document.body.className = theme
    }, [theme])

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
                setIsMenuOpen(false)
                setIsExploreOpen(false)
                setIsProductOpen(false)
            }
        }

        document.addEventListener('mousedown', handleClickOutside)

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    useEffect(() => {
        if (isMenuOpen) {
            document.body.classList.add('no-scroll')
        } else {
            document.body.classList.remove('no-scroll')
        }
    }, [isMenuOpen])

    const imgMenu = isMenuOpen ? "https://gabcomercio.azureedge.net/azul-agro/general/menuClose" : "https://gabcomercio.azureedge.net/azul-agro/general/menuOpen"
    const srcMenu = theme === "light" ? imgMenu + ".svg" : imgMenu + "Dark.svg"
    const altMenu = isMenuOpen ? "Fechar o menu da página" : "Abrir o menu da página"

    const srcMode = theme === "light" ? "https://gabcomercio.azureedge.net/azul-agro/general/dark.svg" : "https://gabcomercio.azureedge.net/azul-agro/general/light.svg"
    const altMode = theme === "light" ? "Ativar tema escuro" : "Ativar tema claro"

    const srcLogo = theme === "light" ? "https://gabcomercio.azureedge.net/azul-agro/general/logo.svg" : "https://gabcomercio.azureedge.net/azul-agro/general/logoDark.svg"
    const srcLineLogo = theme === "light" ? "https://gabcomercio.azureedge.net/azul-agro/general/lineLogo.svg" : "https://gabcomercio.azureedge.net/azul-agro/general/lineLogoDark.svg"
    const srcLogoNH = theme === "light" ? "https://gabcomercio.azureedge.net/azul-agro/general/logoNH.svg" : "https://gabcomercio.azureedge.net/azul-agro/general/logoNHDark.svg"

    const handleMenuClick = (menu: "explore" | "product") => {
        if (menu === "explore") {
            if (isProductOpen) {
                setIsProductOpen(false)
                setTimeout(() => {
                    setIsExploreOpen(true)
                }, 300) // Ajuste o tempo de acordo com a animação de fechamento
            } else {
                setIsExploreOpen(!isExploreOpen)
            }
        }

        if (menu === "product") {
            if (isExploreOpen) {
                setIsExploreOpen(false)
                setTimeout(() => {
                    setIsProductOpen(true)
                }, 300) // Ajuste o tempo de acordo com a animação de fechamento
            } else {
                setIsProductOpen(!isProductOpen)
            }
        }
    }

    return (
        <header className={styles.header}>
            <div className={styles.main} ref={menuRef}>
                <div className={styles.menu}>
                    <LogoContainer srcLogo={srcLogo} srcLineLogo={srcLineLogo} srcLogoNH={srcLogoNH} />
                    <div className={styles.content}>
                        <button type="button" className={styles.mode} onClick={toggleTheme}>
                            <img src={srcMode} alt={altMode} width={24} height={24}/>
                        </button>
                        <button type="button" onClick={() => setIsMenuOpen(!isMenuOpen)}><img src={srcMenu} alt={altMenu} width={36} height={36}/></button>
                    </div>
                </div>

                <nav className={`${styles.menuOpen}${isMenuOpen ? ' ' + styles.show : ''}`}>
                    <button type="button" onClick={() => setIsAlertModalOpen(true)}>Alerta ao consumidor <span className="AZUL-AGRO">M</span></button>

                    <button type="button" onClick={() => handleMenuClick("explore")}>Explorar <span className={`AZUL-AGRO${isExploreOpen ? ' rotate' : ''}`}>b</span></button>
                    <ExploreContainer setIsMenuOpen={setIsMenuOpen} setIsExploreOpen={setIsExploreOpen} styles={styles} isExploreOpen={isExploreOpen} />

                    <button type="button" onClick={() => handleMenuClick("product")}>Produtos <span className={`AZUL-AGRO${isProductOpen ? ' rotate' : ''}`}>b</span></button>
                    <ProductContainer setIsMenuOpen={setIsMenuOpen} setIsProductOpen={setIsProductOpen} styles={styles} isProductOpen={isProductOpen} theme={theme} />

                    <ul className={`${isProductOpen ? styles.contactOpen : styles.contact}`}>
                        <li>
                            <CustomLink className="btn-primary" href="/contato">Fale conosco</CustomLink>
                        </li>
                    </ul>
                </nav>
            </div>
        </header>
    )
}

export default React.memo(HeaderMobile)
