import React from "react"
import CustomLink from "../../CustomLink"
import { ExploreContainerProps } from "@/app/interface/components"

const ExploreContainer: React.FC<ExploreContainerProps> = ({ styles, isExploreOpen, setIsExploreOpen, setIsMenuOpen }) => {
    return (
        <ul className={`${styles.explore}${isExploreOpen ? ' ' + styles.show : ''}`}>
            <li>
                <CustomLink onClick={() => {
                    setIsExploreOpen(false)
                    setIsMenuOpen?.(false)
                }} href="/sobre">
                    <span>Sobre Azul Agro</span>
                    <div>
                        <img src="https://gabcomercio.azureedge.net/azul-agro/general/about.svg" width={95} height={13} loading="lazy" alt="" />
                    </div>
                </CustomLink>
            </li>
            <li>
                <CustomLink onClick={() => {
                    setIsExploreOpen(false)
                    setIsMenuOpen?.(false)
                }} href="/consorcio">
                    <span>Consórcio Azul Agro</span>
                    <div>
                        <img src="https://gabcomercio.azureedge.net/azul-agro/general/consorcio.svg" width={59} height={45} loading="lazy" alt="" />
                    </div>
                </CustomLink>
            </li>
            <li>
                <CustomLink onClick={() => {
                    setIsExploreOpen(false)
                    setIsMenuOpen?.(false)
                }} href="/financiamento">
                    <span>Financiamento</span>
                    <div>
                        <img src="https://gabcomercio.azureedge.net/azul-agro/general/finance.svg" width={23} height={45} loading="lazy" alt="" />
                    </div>
                </CustomLink>
            </li>
            <li>
                <CustomLink href="/pecas" onClick={() => setIsExploreOpen(false)}>
                    <span>Peças</span>
                    <div>
                        <img src="https://gabcomercio.azureedge.net/azul-agro/general/parts.svg" width={55} height={44} loading="lazy" alt="" />
                    </div>
                </CustomLink>
            </li>
            <li>
                <CustomLink onClick={() => {
                    setIsExploreOpen(false)
                    setIsMenuOpen?.(false)
                }} href="/assistencia">
                    <span>Assistência Técnica</span>
                    <div>
                        <img src="https://gabcomercio.azureedge.net/azul-agro/general/support.svg" width={45} height={45} loading="lazy" alt="" />
                    </div>
                </CustomLink>
            </li>
        </ul>
    )
}

export default React.memo(ExploreContainer)