import { useState, useEffect } from 'react'
import Cookies from 'js-cookie'

export const useCookies = () => {
  const [cookiePreferences, setCookiePreferences] = useState({
    necessary: true, analytics: false, advertising: false, performance: false
  })
  const [hasCookie, setHasCookie] = useState(true)

  useEffect(() => {
    const cookieConsent = Cookies.get('cookieConsent')
    if (cookieConsent) {
      setCookiePreferences(JSON.parse(cookieConsent))
      setHasCookie(true)
    } else {
      setHasCookie(false)
    }
  }, [])

  const handleConsent = (consent: any) => {
    setCookiePreferences(consent)
    setHasCookie(true)
    Cookies.set('cookieConsent', JSON.stringify(consent), { expires: 365 })
  }

  return { cookiePreferences, handleConsent, hasCookie }
}
