import React from "react"
import CustomLink from "../../CustomLink"
import { ExploreContainerProps } from "@/app/interface/components"

const ExploreContainer: React.FC<ExploreContainerProps> = ({styles, isExploreOpen, setIsExploreOpen}) => {
    return (
        <ul className={`${styles.explore}${isExploreOpen ? ' ' + styles.show : ''}`}>
            <li>
                <CustomLink href="/sobre" onClick={() => setIsExploreOpen(false)}>
                    <span>Sobre Azul Agro</span>
                    <div>
                        <img src="https://gabcomercio.azureedge.net/azul-agro/general/about.svg" width={178} height={23} loading="lazy" alt="" />
                    </div>
                </CustomLink>
            </li>
            <li>
                <CustomLink href="/consorcio" onClick={() => setIsExploreOpen(false)}>
                    <span>Consórcio Azul Agro</span>
                    <div>
                        <img src="https://gabcomercio.azureedge.net/azul-agro/general/consorcio.svg" width={93} height={71} loading="lazy" alt="" />
                    </div>
                </CustomLink>
            </li>
            <li>
                <CustomLink href="/financiamento" onClick={() => setIsExploreOpen(false)}>
                    <span>Financiamento</span>
                    <div>
                        <img src="https://gabcomercio.azureedge.net/azul-agro/general/finance.svg" width={36} height={71} loading="lazy" alt="" />
                    </div>
                </CustomLink>
            </li>
            <li>
                <CustomLink href="/pecas" onClick={() => setIsExploreOpen(false)}>
                    <span>Peças</span>
                    <div>
                        <img src="https://gabcomercio.azureedge.net/azul-agro/general/parts.svg" width={85} height={68} loading="lazy" alt="" />
                    </div>
                </CustomLink>
            </li>
            <li>
                <CustomLink href="/assistencia" onClick={() => setIsExploreOpen(false)}>
                    <span>Assistência Técnica</span>
                    <div>
                        <img src="https://gabcomercio.azureedge.net/azul-agro/general/support.svg" width={71} height={71} loading="lazy" alt="" />
                    </div>
                </CustomLink>
            </li>
        </ul>
    )
}

export default React.memo(ExploreContainer)