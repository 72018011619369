export const seeders = [
    {
        models: ['PL6000', 'PL7000'],
    }
]

export const sprayers = [
    {
        models: ['Defensor 2500', 'Defensor 2500 Cana-de-Açúcar', 'Defensor 3500 HC'],
    }
]

export const harvester = [
    {
        models: ['CR', 'TC', 'TX'],
    }
]

export const tractors = [
    {
        models: ['Série 30', 'T3F', 'T4', 'T4.75S', 'T5.110S', 'T6', 'T6 Methane Power', 'T7 LWB', 'T7 SWB', 'T8 PLM Intelligence', 'T9 PLM Intelligence', 'TK4', 'TL5', 'TL5 acessível', 'TT e TTF', 'TT4'],
    }
]

export const platforms = [
    {
        models: ['Plataforma Draper', 'Plataforma Rigida', 'Plataforma Superflex', 'Plataformas de Milho'],
    }
]