import { AlertBtnProps } from "@/app/interface/components"
import React, { useEffect, useState } from "react"

const AlertBtn: React.FC<AlertBtnProps> = ({ setIsAlertModalOpen, styles, setIsProductOpen, setIsExploreOpen }) => {
    const [isClient, setIsClient] = useState(false)

    useEffect(() => {
        setIsClient(true)
    }, [])

    if (!isClient) {
        return null
    }

    return (
        <div className={styles.alert}>
            <button type="button" onClick={() => { 
                setIsAlertModalOpen(true)
                setIsExploreOpen(false)
                setIsProductOpen(false)
            }}>
                alerta ao consumidor <span className="AZUL-AGRO">M</span>
            </button>
        </div>
    )
}

export default React.memo(AlertBtn)
