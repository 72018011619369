import { AppProps } from 'next/app'
import '@/styles/globals.scss'
import { useCookies } from '@/app/hooks/useCookies'
import React, { useState, useCallback, useEffect } from 'react'
import dynamic from 'next/dynamic'
import { ThemeProvider } from '@/app/contexts/ThemeContext'
import CookieConsent from '@/app/components/UI/CookieConsent'
import Header from '@/app/components/UI/Header'
import Footer from '@/app/components/UI/Footer'
import Head from 'next/head'
import useGoogleTagManager from '@/app/hooks/useGoogleTagManager'
import { AuthProvider } from '@/app/contexts/AuthContext'
import { useRouter } from 'next/router'
import { BannerProvider } from '@/app/contexts/BannerContext'
import HeaderCMS from '@/app/components/UI/HeaderCMS'

const DynamicAlertModal = dynamic(() => import('@/app/components/UI/AlertModal'), { ssr: false })
const DynamicConditionsModal = dynamic(() => import('@/app/components/UI/ConditionsModal'), { ssr: false })

const cmsPath = '/cms'
const specialPaths = ['/programa-de-privacidade', cmsPath]

const needsStandardLayout = (path: string): boolean => {
  return !specialPaths.some(specialPath => path === specialPath || path.includes(specialPath))
}

const App = ({ Component, pageProps }: AppProps) => {
  const { cookiePreferences, handleConsent, hasCookie } = useCookies()
  const [isAlertModalOpen, setIsAlertModalOpen] = useState(false)
  const router = useRouter()
  const { asPath } = router
  const [newBanner, setNewBanner] = useState<boolean>(false)
  const [isConditionsModalOpen, setIsConditionsModalOpen] = useState(false)
  const [conditionsContent, setConditionsContent] = useState<string>('')

  const [isClient, setIsClient] = useState(false)

  useEffect(() => {
    // Isso garante que o código só roda no cliente
    setIsClient(true)
  }, [])

  // Dados que serão compartilhados pelo BannerProvider
  const bannerData = {
    contents: pageProps.contents || [],
    defenseTypes: pageProps.defenseTypes || []
  }

  const handleAccept = useCallback(() => {
    handleConsent({ necessary: true, analytics: true, advertising: true, performance: true })
  }, [handleConsent])

  const handleDecline = useCallback(() => {
    handleConsent({ necessary: true, analytics: false, advertising: false, performance: false })
  }, [handleConsent])

  useGoogleTagManager()

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>
      {needsStandardLayout(asPath) ? (
        <ThemeProvider>
          <BannerProvider {...bannerData}>
            <Header setIsAlertModalOpen={setIsAlertModalOpen} />
            <Component {...pageProps} cookiePreferences={cookiePreferences} setIsConditionsModalOpen={setIsConditionsModalOpen} setConditionsContent={setConditionsContent} />
            {/*<CookieConsent
              onAccept={handleAccept}
              onDecline={handleDecline}
              buttonText="Aceitar todos os cookies"
              declineButtonText="Apenas os necessários"
              text='Ao clicar em "Aceitar todos os cookies", concorda com o armazenamento de cookies no seu dispositivo para melhorar a navegação e analisar a utilização do site, além de ajudar nas nossas iniciativas de marketing.'
              cookieURL='/cookies'
              hasCookie={hasCookie}
            />*/}
            {isConditionsModalOpen && <DynamicConditionsModal setIsConditionsModalOpen={setIsConditionsModalOpen} content={conditionsContent} />}
            {isAlertModalOpen && <DynamicAlertModal setIsAlertModalOpen={setIsAlertModalOpen} />}
            <Footer />
          </BannerProvider>
        </ThemeProvider>
      ) : asPath.includes(cmsPath) ? (
        <AuthProvider>
          {/* Renderiza o HeaderCMS somente quando estamos no cliente */}
          {isClient && router.pathname !== cmsPath && window?.location?.hostname !== 'azulagromaquinas.com.br' && (
            <HeaderCMS setNewBanner={setNewBanner} />
          )}
          <Component newBanner={newBanner} setNewBanner={setNewBanner} {...pageProps} />
        </AuthProvider>
      ) : (
        <ThemeProvider>
          <Component {...pageProps} cookiePreferences={cookiePreferences} />
          {/*<CookieConsent
            onAccept={handleAccept}
            onDecline={handleDecline}
            buttonText="Aceitar todos os cookies"
            declineButtonText="Apenas os necessários"
            text='Ao clicar em "Aceitar todos os cookies", concorda com o armazenamento de cookies no seu dispositivo para melhorar a navegação e analisar a utilização do site, além de ajudar nas nossas iniciativas de marketing.'
            cookieURL='/cookies'
            hasCookie={hasCookie}
          />*/}
        </ThemeProvider>
      )}
    </>
  )
}

export default App
