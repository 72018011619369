import React from "react"
import CustomLink from "./CustomLink"
import styles from "@/styles/LogoContainer.module.scss"
import { LogoContainerProps } from "@/app/interface/components"

const LogoContainer: React.FC<LogoContainerProps> = ({ srcLogo, srcLineLogo, srcLogoNH, widthLogo=95, heightLogo=22, heightLine=38, widthLogoNH=101, heightLogoNH=18 }) => {
    return (
        <div className={styles.main}>
            <CustomLink href="/">
                <img src={srcLogo} alt="Azul Agro, Máquinas e Equipamentos" width={widthLogo} height={heightLogo} />
            </CustomLink>
            
            <img src={srcLineLogo} alt="" width={1} height={heightLine} />

            <CustomLink href="https://agriculture.newholland.com/pt-br/brasil" target="_blank">
                <img src={srcLogoNH} alt="New Holland" width={widthLogoNH} height={heightLogoNH} />
            </CustomLink>
        </div>
    )
}

export default React.memo(LogoContainer)