import React, { useEffect } from "react";
import styles from "@/styles/FreemodeSlide.module.scss";
import { FreemodeSlideProps } from "@/app/interface/components";
import useEmblaCarousel from "embla-carousel-react";

const FreemodeSlide: React.FC<FreemodeSlideProps> = ({ children, gap="0px", padding="0px" }) => {
    const [emblaRef, emblaApi] = useEmblaCarousel({
        loop: false,
        dragFree: true,
        containScroll: 'trimSnaps',
    })
    
    useEffect(() => {
        if (emblaApi) {
            emblaApi.on('scroll', () => {
                // Optional: Add your scroll-related logic here
            })
        }
    }, [emblaApi])
    
    return (
        <div className={`${styles.embla} no-select`} ref={emblaRef} style={{padding: padding}}>
            <div className={styles.embla__container} style={{gap: gap}}>
                {React.Children.map(children, (child, index) => (
                <div className={styles.embla__slide} key={index}>
                    {child}
                </div>
                ))}
            </div>
        </div>
    )
}

export default React.memo(FreemodeSlide);
