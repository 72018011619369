import React, { useState } from "react"
import CustomLink from "../../CustomLink"
import { ProductContainerProps } from "@/app/interface/components"
import FreemodeSlide from "../../FreemodeSlide"
import { seeders, sprayers, harvester, tractors, platforms } from "@/app/utils/machines"

const buttonData = [
    { name: 'Plantadeira', machine: 'seeders' },
    { name: 'Pulverizadores', machine: 'sprayers' },
    { name: 'Colheitadeira', machine: 'harvester' },
    { name: 'Tratores', machine: 'tractors' },
    { name: 'Plataformas', machine: 'platforms' }
]

const getMachines = (machine: string) => {
    switch (machine) {
        case 'seeders': return seeders
        case 'sprayers': return sprayers
        case 'harvester': return harvester
        case 'tractors': return tractors
        case 'platforms': return platforms
        default: return []
    }
}

const ProductContainer: React.FC<ProductContainerProps> = ({ styles, isProductOpen, theme, setIsProductOpen }) => {
    const [activeButton, setActiveButton] = useState<string>(buttonData[0].machine)

    const handleButtonClick = (buttonName: string) => {
        setActiveButton(buttonName)
    }

    const generateLinks = (machines: any) => {
        return machines.flatMap((machine: any) => machine.models.map((model: string) => {
            const normalizedModel = model.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/ç/g, "c").replace(/\s+/g, "-").replace(".", "-").toLowerCase()
            const imgSrc = 'https://gabcomercio.azureedge.net/azul-agro/general/machines/' + normalizedModel + '.webp'
            const url = `/${normalizedModel}`
            return (
                <CustomLink onClick={() => setIsProductOpen(false)} key={model} href={url} className={styles.machines}>
                    <img src={imgSrc} alt="" width={152} height={152} loading="lazy" />
                    {model}
                </CustomLink>
            )
        }))
    }

    const getImgButtonData = (machine: string) => {
        let img = `https://gabcomercio.azureedge.net/azul-agro/general/${machine}`

        if (activeButton === machine) return `${img}Active.svg`
        if (theme === 'dark') return `${img}Dark.svg`

        return `${img}.svg`
    }

    return (
        <div className={`${styles.products}${isProductOpen ? ' ' + styles.show : ''}`}>
            <FreemodeSlide gap="16px" padding="0px 16px">
                {buttonData.map(button => (
                    <button type="button" 
                        key={button.name}
                        className={`${styles.selectModel} ${activeButton === button.machine ? styles.active : ''}`}
                        onClick={() => handleButtonClick(button.machine)}
                    >
                        <img src={getImgButtonData(button.machine)} alt="" width={60} height={60} loading="lazy" />
                        {button.name}
                    </button>
                ))}
            </FreemodeSlide>

            <FreemodeSlide gap="16px" padding="0px 16px">
                {generateLinks(getMachines(activeButton))}
            </FreemodeSlide>
        </div>
    )
}

export default React.memo(ProductContainer)
